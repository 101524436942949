import { Injectable } from "@angular/core";

import { Action, Selector, StateContext, State } from "@ngxs/store";
import { finalize, tap } from "rxjs";
import { User as UserModel } from "../../models/user/user.model";
import { OAuthService } from "angular-oauth2-oidc";
import { User } from "./user.actions";
import { UserService } from "../../services/user/user.service";


export class UserStateModel {
	user: any;
	loading: boolean;
}

@State<UserStateModel>({
	name: 'userstate',
	defaults: {
		user: {},
		loading: false
	}
})

@Injectable()
export class UserState {
	constructor(private oauthService: OAuthService, private userService: UserService) { }

	@Selector()
	static getUser(state: UserStateModel) {
		return state.user;
	}

	@Selector()
	static loading(state: UserStateModel) {
		return state.loading;
	}

	@Action(User.Fetch)
	getUser(ctx: StateContext<UserStateModel>) {
		console.log('getUser');
		const state = ctx.getState();

		ctx.patchState({
			loading: true
		});

		return this.userService.fetchUser().pipe(tap((returnData: any) => {

			const user = {
				id: this.oauthService.getIdentityClaims()['id'],
				first_name: this.oauthService.getIdentityClaims()['name']['first'],
				last_name: this.oauthService.getIdentityClaims()['name']['last'],
				email: this.oauthService.getIdentityClaims()['email'],
				role: returnData.data.role,
				created_at: returnData.data.created_at
			}

			ctx.patchState({
				...state,
				user: user
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}
}