import { Injectable } from "@angular/core";
import { HttpParams } from '@angular/common/http';

import { Action, Selector, StateContext, State, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap, finalize } from "rxjs";

import { GatewayService } from "src/app/shared/services/gateway/gateway.service";
import { GatewaysEvents } from "./gateways_events.actions";
import { OrganizationState } from "../organization/organization.state";

export class GatewaysEventsStateModel {
	filter: any
	gateway_events: any
	loading: boolean
}

@State<GatewaysEventsStateModel>({
	name: 'gatewayseventsstate',
	defaults: {
		filter: {
			page: '',
			per_page: '',
			q: '',
			total: '',
			s: []
		},
		gateway_events: [],
		loading: false
	}
})

@Injectable()
export class GatewaysEventsState {
	constructor(private gatewayService: GatewayService, private store: Store) { }

	@Selector()
	static currentPage(state: GatewaysEventsStateModel): number {
		return state.filter.page;
	}

	@Selector()
	static itemsOnPage(state: GatewaysEventsStateModel): number {
		return state.filter.per_page;
	}

	@Selector()
	static query(state: GatewaysEventsStateModel): string {
		return state.filter.q;
	}

	@Selector()
	static total(state: GatewaysEventsStateModel): string {
		return state.filter.total;
	}

	@Selector()
	static filter(state: GatewaysEventsStateModel): string {
		return state.filter;
	}

	@Selector()
	static selectGatewayEvents(state: GatewaysEventsStateModel) {
		return state.gateway_events;
	}

	@Selector()
	static loading(state: GatewaysEventsStateModel): boolean {
		return state.loading;
	}

	@Action(GatewaysEvents.SetFilter)
	setFilter(ctx: StateContext<GatewaysEventsStateModel>, action: GatewaysEvents.SetFilter) {
		return ctx.setState(patch<GatewaysEventsStateModel>({
			filter: action.filter
		}));
	}

	@Action(GatewaysEvents.FetchAll)
	getGateways(ctx: StateContext<GatewaysEventsStateModel>) {
		const state = ctx.getState();
		let filter: any = state.filter ? { ...state.filter } : {};

		delete filter.total;

		filter = {
			...filter,
			s: filter.s ? filter.s.join(',') : []
		}

		const options = {
			params: new HttpParams({
				fromObject: filter
			})
		}

		const orga = this.store.selectSnapshot(OrganizationState.getSelecetdOrganization);
		console.log('orga', orga);

		ctx.patchState({ loading: true });

		return this.gatewayService.fetchGatewaysEvents(options).pipe(tap((returnData: any) => {
			console.log('returnData events', returnData);

			const state = ctx.getState();
			ctx.patchState({
				...state,
				gateway_events: returnData.data,
				filter: {
					...state.filter,
					page: returnData.current_page,
					per_page: returnData.per_page,
					total: returnData.total
				},
			});
		}), finalize(() => {
			ctx.patchState({
				loading: false
			});
		}));
	}
}
