import { Component, OnInit } from '@angular/core';

import { AuthService } from './auth.service';
import { WebsocketService } from './shared/services/websocket/websocket.service';

import { Observable, switchMap } from 'rxjs';

import { NewVersionCheckerService } from './shared/services/version/version.service';

import { MessageService } from 'primeng/api';

import { Select, Store } from '@ngxs/store';
import { GatewayDevices } from './shared/state/gateway_devices/gateway_devices.actions';
import { User } from './shared/state/user/user.actions';
import { ApiErrorState } from './shared/state/api_error/api_error.state';
import { Organization } from './shared/state/organization/organization.actions';
import { Organization as OrganizationModel } from './shared/state/organization/organization.model';
import { OrganizationState } from './shared/state/organization/organization.state';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [MessageService]
})
export class AppComponent implements OnInit {
	wsMessages$: any;
	isAuthenticated: boolean = false;


	@Select(OrganizationState.getOrganizations) $organizations: Observable<OrganizationModel[]>

	@Select(OrganizationState.getSelecetdOrganization) $organization: Observable<OrganizationModel>;
	@Select(ApiErrorState) $error: Observable<any>;

	constructor(
		private authService: AuthService,
		private wsService: WebsocketService,
		private store: Store,
		private messageService: MessageService,
		public versionService: NewVersionCheckerService
	) { }

	ngOnInit() {

		this.$error.subscribe((error: any) => {
			console.log('error', error);
			this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
		});

		this.authService.isAuthenticated$.subscribe(isAuthenticated => {
			console.log('isAuthenticated', isAuthenticated);
			this.isAuthenticated = isAuthenticated;
			if (!isAuthenticated) {
				this.wsMessages$.unsubscribe();
				this.wsService.close();
				return;
			}

			this.store.dispatch(new User.Fetch);
			this.wsService.connect();
		});

		this.wsMessages$ = this.wsService.$messages.subscribe((message: any) => {
			// console.log(message);
			if (message.message) return;
			this.store.dispatch(new GatewayDevices.UpdateDeviceState(
				message.gateway_id,
				message.device_id,
				message.event.component,
				message.event.capability,
				message.event.attribute,
				message.event.value,
				message.event.created_at
			))
		}, (error: any) => { console.log('WS error', error) });

		this.store.dispatch(new Organization.FetchAll);

		this.$organization.subscribe((organization: OrganizationModel) => {
			if (!organization) {
				this.$organizations.subscribe((organizations: OrganizationModel[]) => {
					this.store.dispatch(new Organization.SelectOrganization(organizations[0].id));
				});
			}
		});
	}

	reload() {
		window.location.reload();
	}

	logout() {
		this.authService.logout();
	}

	reset() {
		// this.store.reset(null);
		this.store.dispatch(new Organization.ResetState());
	}
}
