import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { computePosition, offset, flip, shift, Placement, autoUpdate } from '@floating-ui/dom'
import { AuthService } from 'src/app/auth.service';
import { Observable, take } from 'rxjs'
import { Configuration, FrontendApi } from '@ory/kratos-client';
import { Select, Store } from '@ngxs/store';
import { UserState } from 'src/app/shared/state/user/user.state';
import { User } from 'src/app/shared/models/user/user.model';
import { Organization } from 'src/app/shared/state/organization/organization.actions';
import { OrganizationState } from 'src/app/shared/state/organization/organization.state';
import { Organization as OrganizationModel } from 'src/app/shared/state/organization/organization.model';
import { ConfirmationService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	providers: [ConfirmationService]
})
export class HeaderComponent implements OnInit, AfterViewInit {

	isOpen: boolean = false;
	width: number = 0;
	position: Placement = "bottom-start";

	lastSelectedOrganization: any;

	@ViewChild('dropdown') dropdown!: ElementRef;
	@ViewChild('button') button!: ElementRef;

	@ViewChild('dropdownOrganizations') dropdownOrganizations: Dropdown;

	@Select(UserState.getUser) $user: Observable<User>;
	user: User;

	@Select(OrganizationState.getOrganizations) $organizations: Observable<any>;

	@Select(OrganizationState.getSelecetdOrganization) $organization: Observable<OrganizationModel>;
	organization: any;

	constructor(private authService: AuthService, private store: Store, private router: Router, private route: ActivatedRoute, private location: Location, private confirmationService: ConfirmationService) { }

	get userName() {
		return `${this.user.first_name} ${this.user.last_name}`;
	}

	get userEmail() {
		return this.user.email;
	}

	ngOnInit(): void {
		this.$user.subscribe((user: User) => this.user = user);

		this.$organization.subscribe((organization: OrganizationModel) => this.organization = organization as any);
	}

	toggle() {
		this.isOpen = !this.isOpen;
		this.calculatePosition();
	}

	calculatePosition() {
		computePosition(this.button.nativeElement, this.dropdown.nativeElement,
			{
				placement: this.position,
				middleware: [offset(8), flip(), shift()]
			}
		).then(({ x, y }: { x: number, y: number }) => {
			this.dropdown.nativeElement.style.left = x + 'px';
			this.dropdown.nativeElement.style.top = y + 'px';
			this.width = this.button.nativeElement.offsetWidth;
		});
	}

	ngAfterViewInit() {
		autoUpdate(this.button.nativeElement, this.dropdown.nativeElement, () => {
			if (!this.isOpen) return;
			this.calculatePosition();
		});
	}

	// Onclick outside the dropdown, close it
	@HostListener('document:click', ['$event'])
	clickout(event: Event) {
		if (!this.dropdown.nativeElement.contains(event.target) && this.isOpen && !this.button.nativeElement.contains(event.target)) {
			this.isOpen = false;
		}
	}

	logout() {
		this.authService.logout();
	}

	organizationChanged($event: OrganizationModel) {
		console.log('organizationChanged', $event);


		this.confirmationService.confirm({
			message: 'Are you sure you want to switch the organisation? You will be redirected to the dashboard!',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			acceptIcon: "none",
			rejectIcon: "none",
			rejectButtonStyleClass: "p-button-text",
			accept: () => {
				this.store.dispatch(new Organization.SelectOrganization($event.id));
				console.log(this.router.url);
				console.log(this.router.routerState.root.snapshot);
				this.router.navigate(['/dashboard']);
			},
			reject: () => {
				console.log('lastSelectedOrganization', this.lastSelectedOrganization);
				console.log('this.organization', this.organization);
				this.organization = this.lastSelectedOrganization;
			}
		});
	}

	organizationsShow() {
		console.log(this.router.routerState.snapshot.url.split('/').at(-1));
		this.lastSelectedOrganization = this.dropdownOrganizations.value;
	}
}
