<nav class="border-b border-secondary px-4 py-2.5 fixed left-0 right-0 top-0 z-[1003] bg-gray"
	[ngStyle]="{'background': (($organization | async)?.id == 9) ? 'linear-gradient(90deg, #94126A 18.02%, #0060AD 43.28%)' : '#f1f5f9'}">
	<div class="flex flex-wrap justify-between items-center">
		<div class="flex justify-start items-center">
			<a href="" class="flex items-center justify-between mr-4">
				<img *ngIf="(($organization | async)?.id == 9)" src="https://abilitybeyond.org/wp-content/themes/ability-beyond-2022/images/logo.png"
					class="mr-3 h-10" alt="AI Pilot Logo" />
				<img *ngIf="(($organization | async)?.id != 9)" src="./assets/img/aeocloud_logo.svg" class="mr-3 h-10" alt="AeoCloud Logo" />
				<span class="self-center text-2xl font-semibold whitespace-nowrap">AeoCloud</span>
			</a>
		</div>

		<div class="flex items-center gap-2 lg:order-2" *isRole="'superadmin'">
			<span>{{ ($organization | async)?.name }}</span>
			<p-dropdown *ngIf="$organizations | async as organizations" [options]="($organizations | async)" [(ngModel)]="organization"
				(ngModelChange)="organizationChanged($event)" (onShow)="organizationsShow()" optionLabel="name" placeholder="Select a organization"
				[filter]="true" filterBy="name" emptyMessage="No Gateways" #dropdownOrganizations></p-dropdown>
		</div>
		<div class="flex items-center lg:order-2">
			<button type="button"
				class="flex mx-3 text-sm bg-secondary rounded-full md:mr-0 focus:ring-4 focus:ring-primary-light items-center justify-center overflow-hidden w-8 h-8"
				id="user-menu-button" aria-expanded="false" (click)="toggle()" #button>
				<span class="sr-only">Open user menu</span>
				<!-- <img class="w-8 h-8 rounded-full" src="https://xsgames.co/randomusers/assets/avatars/male/69.jpg" alt="user photo" /> -->
				<fa-icon size="2xl" [icon]="['fas', 'user']"></fa-icon>
			</button>
			<!-- Dropdown menu -->
			<div class="absolute z-50 my-4 min-w-fit text-primary list-none bg-gray rounded divide-y divide-secondary shadow" #dropdown
				[style.display]="isOpen ? 'block' : 'none'" [style.width]="width + 'px'">
				<div class="py-3 px-4">
					<span class="block text-sm font-semibold text-primary">{{ userName }}</span>
					<span class="block text-sm text-primary truncate">{{ userEmail }}</span>
				</div>
				<ul class="py-1 text-primary" aria-labelledby="dropdown">
					<!-- <li>
						<a routerLink="profile" class="block py-2 px-4 rounded text-sm hover:bg-secondary">
							My profile
						</a>
					</li> -->
					<li>
						<a pButton href="https://auth.cloud.aeotec.com/setting" target="_blank" class="block text-left">
							<span class="mr-2">Account settings</span>
							<fa-icon [icon]="['fas', 'up-right-from-square']"></fa-icon>
						</a>
					</li>
				</ul>
				<ul class="py-1 text-primary" aria-labelledby="dropdown">
					<li>
						<button pButton class="w-full text-left" (click)="logout()">Sign out</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>
<p-confirmDialog />